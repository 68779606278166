<template>
  <IchibanTemplate>
    <template #body>
      <div class="pa-4">
        <v-text-field
          v-model.number="amount"
          prefix="NT$"
          dark
          class="amount-input"
          type="number"
        ></v-text-field>
        <div class="white--text mb-8">
          <p>一次最多儲值 {{ max }} 元</p>
        </div>
        <div class="topup-buttons mb-2">
          <v-btn @click="topUp(100)">＋100</v-btn>
          <v-btn @click="topUp(1000)">＋1000</v-btn>
          <v-btn @click="topUp(5000)">＋5000</v-btn>
        </div>
        <div>
          <v-btn
            :href="getHref()"
            :disabled="isIllegalPayload"
            color="cyan"
            width="100%"
            dark
            large
          >
            儲值
          </v-btn>
        </div>
      </div>
    </template>
  </IchibanTemplate>
</template>

<script>
import { mapState } from "vuex";
import { apiURL } from "../../../env.js";
import IchibanTemplate from "@/views/Client/IchibanTemplate.vue";

export default {
  name: "",
  props: [],
  components: { IchibanTemplate },
  data: () => ({
    amount: 0,
    max: 10000,
  }),
  computed: {
    ...mapState("clientModule", {
      userID: (state) => state.userID,
      user: (state) => state.user,
    }),
    isIllegalPayload() {
      const isNaN = Number.isNaN(Number(this.amount));
      return (
        !this.amount || this.amount <= 0 || this.amount > this.max || isNaN
      );
    },
  },
  methods: {
    topUp(val) {
      const newVel = this.amount + val;

      if (newVel > this.max) {
        this.amount = this.max;
        return;
      } else {
        this.amount = newVel;
      }
    },
    goToPayRoute() {
      this.axios.get(`/ecpay/pay/${this.userID}/${this.amount}`);
    },
    getHref() {
      return apiURL + `/ecpay/pay/${this.userID}/${this.amount}`;
    },
  },
  created() {},
};
</script>

<style scoped lang="scss">
.amount-input {
  font-size: 24px;
}

.topup-buttons {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}
</style>
